import React, { useState, useEffect, useRef } from "react";
import { ContentsState } from "../../Context/ContentProvider";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faSignOutAlt,
  faHome,
  faGlobe,
  faList,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";
import { faDropbox } from "@fortawesome/free-brands-svg-icons";
import ToggleSwitch from "./ToggleSwitch";

const Navbar = ({ selectedUser, toggleSidebar }) => {
  const { user } = ContentsState();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [logoImgTog, setLogoImgTog] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      const storedLogo = localStorage.getItem("logoImage");
      if (storedLogo) {
        setLogoImage(storedLogo);
        // You can also decide here whether the logo is an image or text based on the stored value.
      } else {
        fetchData();
      }
    }
  }, [currentUser]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/about`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setLogoImage(data.logoid);
        localStorage.setItem("logoImage", data.logoid); // Store the image URL in local storage
        if (data.logoText) {
          setLogoImgTog(false);
        } else {
          setLogoImgTog(true);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("logoImage"); // Remove the logo from local storage
    navigate("/");
    window.location.reload(); // Refresh the page
  };

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className={`bg-navbar p-4 shadow flex justify-between items-center ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      <div className="flex items-center">
        <button onClick={toggleSidebar} className="mr-4">
          <FontAwesomeIcon icon={faList} className="text-gray-700" />
        </button>
        <ToggleSwitch isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      </div>
      <div className="flex items-center ml-auto relative">
        <div className="relative" ref={dropdownRef}>
          <div
            className="flex items-center cursor-pointer"
            onClick={toggleDropdown}
          >
            {/* <img
              src={`${baseUrl}/uploads/${currentUser.url}/${logoImage}`}
              alt="Profile"
              className="h-8 w-8 mr-3 rounded-sm"
            /> */}
            <span className="font-semibold pr-2 text-gray-700 mr-1">
              {user.name}
            </span>
            <FontAwesomeIcon icon={faBell} className="text-blue-500 mr-4" />
          </div>
          {dropdownVisible && (
            <div className="absolute right-0 mt-2 mx-0 w-72 bg-dropdown border border-gray-200 rounded shadow-lg z-50">
              <div className="p-4 border-b border-gray-200">
                <div className="flex items-center">
                  <img  src={`${baseUrl}/uploads/${currentUser.url}/${logoImage}`} alt="" className="h-10 w-10 mr-2" />
                  <div>
                    <h4 className="font-semibold text-gray-700">{user.name}</h4>
                    <p className="text-xs text-gray-700 capitalize">
                      {user.role}
                    </p>
                  </div>
                </div>
              </div>
              <ul className="py-1">
                <li>
                  <Link
                    to="/"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100   hover:text-black transition-colors duration-200 ease-in-out font-semibold"
                  >
                    <FontAwesomeIcon
                      icon={faHome}
                      className="mr-2 text-blue-500"
                    />{" "}
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/managepassword"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100  hover:text-black transition-colors duration-200 ease-in-out font-semibold"
                  >
                    <FontAwesomeIcon
                      icon={faKey}
                      className="text-green-500 mr-2"
                    />{" "}
                    Manage Password
                  </Link>
                </li>
                <li>
                  <a
                    href={`https://qrdcard.com/${currentUser.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-black transition-colors duration-200 ease-in-out font-semibold"
                  >
                    <FontAwesomeIcon
                      icon={faGlobe}
                      className="text-blue-500 mr-2"
                    />{" "}
                    View QR Website
                  </a>
                </li>
                <hr />
                <li>
                  <button
                    onClick={logoutHandler}
                    className="block w-full text-left px-4 py-2 text-red-500 hover:bg-gray-100  font-bold transition-colors duration-200 ease-in-out"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
