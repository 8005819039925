import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const Menu = () => {
  const { guid } = useParams();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const response = await axios.get(
          `https://newqrdapi.qrdcard.com/api/restraunt/menu?guid=${guid}`
        );
        console.log(response.data.menu)
        setImages(response.data.menu);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch menu images");
        setLoading(false);
      }
    };

    fetchMenu();
  }, [guid]);

  if (loading) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  if (error) {
    return <div className="text-center mt-10 text-red-500">{error}</div>;
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-0 h-full overflow-y-auto">
      {images.map((url, index) => (
        <div key={index} className="flex items-center justify-center h-full w-full">
          <img
            src=  {`${baseUrl}/uploads/${guid}/${url}`}
            alt={`Image ${index + 1}`}
            className="object-contain max-h-full max-w-full rounded-md w-full"
          />
        </div>
      ))}
    </div>
  );
};

export default Menu;
