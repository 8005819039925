import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import Swal from "sweetalert2";
import ToggleSwitch from "./common/ToggleButton";
import Shimmer from "./common/Shimmer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faLightbulb,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

function ManageBlog({ selectedUser }) {
  const [isOpen, setIsOpen] = useState(true);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [editBlogId, setEditBlogId] = useState(null); // State to track which blog is being edited
  const { user } = ContentsState();
  const [shortDesc, setShortDesc] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const postDetails = async (Images) => {
    // Check if no image is selected
    if (!Images) {
      toast.error("No image selected");
      return;
    }
  
    // Check for valid file type (JPEG or PNG)
    if (Images.type !== "image/jpeg" && Images.type !== "image/png") {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }
  
    // Check for valid file size (max 2MB)
    if (Images.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }
  
    // Prepare FormData for upload
    const formData = new FormData();
    formData.append("file", Images);  // Using `Images` (parameter name) instead of `file`
  
    // Assuming `currentUser` is defined and has a `url` field
    const folderName = currentUser?.url || "def";  // Safely access `url` with optional chaining
    formData.append("folder", folderName); // Append the folder name to the form data
  
    try {
      // Upload the image via API
      const response = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Assuming the backend returns the `fileName`
      const uploadedImageName = response.data.fileName;
      setImage(uploadedImageName); // Set only the image name (not full URL or file)
      toast.success("Image uploaded successfully");
    } catch (error) {
      // Log detailed error information for debugging
      if (error.response) {
        console.error("Error response from server:", error.response.data);
        toast.error("Error from server: " + (error.response.data.message || "Upload failed"));
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        toast.error("No response from server. Please try again later.");
      } else {
        console.error("Error in request setup:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  


  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}/api/blog`, {
        params: { url: currentUser.url },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      const { data } = response;
      if (data) {
        setBlogs(data.blogs || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);
  const handleToggleStatus = (index, status) => {
    const statusReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/blog/changeStatus`,
          {
            params: {
              emailId: currentUser.email,
              index: index,
              status: status,
            },
          },
          config
        );
        fetchData();
        toast.success("Status Changed");
      } catch (error) {
        console.error("Error changing status", error);
        toast.error("Error Changing Status");
      }
    };

    statusReq();
  };
  const handleSubmit = () => {
    const currentContent = content;
    const postData = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        const url = editBlogId
          ? `${baseUrl}/api/blog/updateBlog/${editBlogId}`
          : `${baseUrl}/api/blog/postBlog`;
        const method = editBlogId ? "put" : "post";

        const { data } = await axios({
          method,
          url,
          data: {
            emailId: currentUser.email,
            blogContent: currentContent,
            blogTitle: title,
            imageId: image,
            shortDesc: shortDesc,
          },
          headers: config.headers,
        });

        toast.success(`${editBlogId ? "Update" : "Upload"} Success`);
        fetchData();
        setTitle("");
        setContent("");
        setImage(null);
        setShortDesc(""); // Clear shortDesc after submitting
        setEditBlogId(null); // Reset editBlogId after successful edit
      } catch (err) {
        console.log(err);
        toast.error(`${err.response.data} `);
      }
    };

    if (!title || !content || !image) {
      toast.error("Fill all the fields");
      return;
    }
    postData();
  };

  const handleDelete = async (blogId) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/blog/deleteBlog/`,
          {  currentUser: currentUser.url, emailId: currentUser.email, blogId: blogId },
          config
        );

        if (response.status !== 200) {
          throw new Error("Failed to delete Blog");
        }
        Swal.fire({
          title: "Deleted!",
          text: "Your Blog has been deleted.",
          icon: "success",
        });
        fetchData(); // Refresh the blogs list after successful delete
      } catch (err) {
        console.log(err);
        toast.error("Delete Failed");
      }
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Blog",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  const handleEdit = (blog) => {
    setTitle(blog.blogTitle);
    setContent(blog.blogContent);
    // setImage(blog.imageId);
    setShortDesc(blog.shortDesc);
    setEditBlogId(blog._id); // Set the id of the blog being edited
  };

  const renderBlogs = () => {
    if (isLoading) {
      return <Shimmer />; // Show shimmer effect while loading
    }

    if (blogs.length === 0) {
      return (
        <div className="bg-white p-4 rounded shadow-md justify-center font-bold  text-gray-700">
          No Blogs to display.
        </div>
      );
    }
    const blogRows = [];
    for (let i = 0; i < blogs.length; i += 3) {
      const row = blogs.slice(i, i + 3);
      const rowContent = (
        <div key={i} className="flex flex-wrap -mx-4">
          {row.map((blog, index) => (
            <div key={i + index} className="w-full md:w-1/3 px-4 mb-4">
              <div className="bg-card p-4 rounded shadow">
                <h2 className="text-xl font-bold mb-2  text-gray-700">{blog.blogTitle}</h2>
                <img
                  src= {`${baseUrl}/uploads/${currentUser.url}/${blog.imageId}`}
                  alt={blog.blogTitle}
                  className="mb-2 w-full h-32 object-cover rounded"
                />
                <div
                  dangerouslySetInnerHTML={{ __html: blog.blogContent }}
                  className="mb-2  text-gray-700"
                ></div>
                <div className="flex space-x-2 justify-between">
                  <button
                    onClick={() => handleEdit(blog)}
                    className="bg-yellow-500 text-white p-2 rounded mt-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                  <ToggleSwitch
                    isActive={blog.status === "active" ? true : false}
                    onToggle={() =>
                      handleToggleStatus(
                        i + index,
                        blog.status === "active" ? "inActive" : "active"
                      )
                    }
                  />
                  <button
                    onClick={() => handleDelete(i + index)} // Pass the combined index
                    className="bg-red-500 text-white p-2 rounded mt-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
      blogRows.push(rowContent);
    }

    return blogRows;
  };

  return (
    <div className={`flex h-screen bg-gray-200 overflow-hidden ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser && (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser && (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode}/>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase  text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage Blog
          </h1>
          {isFormVisible && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Blog Title
                  </label>
                  <input
                    type="text"
                    placeholder="Blog Title"
                    value={title}
                    onChange={handleTitleChange}
                    className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="imageInput"
                    className=" text-gray-700 text-sm font-bold mb-2 cursor-pointer flex mt-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
                      <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                    </svg>
                    <span className="ml-1">Select Blog Image</span>
                  </label>
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={(e) => postDetails(e.target.files[0])}
                    className="hidden"
                  />
                  {image && (
                    <div className="relative w-32 h-32 mb-4">
                      <img
                        src= {`${baseUrl}/uploads/${currentUser.url}/${image}`}
                        alt="Selected"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute inset-0 flex items-center justify-center rounded-lg"></div>
                    </div>
                  )}
                  <p className="text-gray-700 font-bold">
                    <FontAwesomeIcon
                      icon={faLightbulb}
                      className="text-yellow-300"
                    />{" "}
                    [Recommended size 1080 × 1080 and File size less than 1 MB]
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Blog Short Description:
                </label>
                <input
                  type="text"
                  placeholder="Blog Short Description"
                  value={shortDesc}
                  onChange={(e) => setShortDesc(e.target.value)}
                  className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
                />
              </div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Blog Description:
              </label>
              <ReactQuill
                value={content}
                onChange={handleContentChange}
                className="mb-6 my-quill-editor"
                style={{ height: "200px" }}
              />
              <div className="flex justify-center md:justify-start space-x-4">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white p-2 mt-9 rounded"
                >
                  {editBlogId ? "Update Blog" : "Upload Blog"}{" "}
                  {/* Change button text */}
                </button>
              </div>
            </div>
          )}
          <div className="flex justify-center mt-2">
            <button
              onClick={toggleFormVisibility}
              className="text-gray-700 focus:outline-none"
            >
              <svg
                className={`w-6 h-6 transform transition-transform duration-300 ${
                  isFormVisible ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
          </div>
          <h2
            className="text-2xl font-bold mb-5 uppercase  text-gray-700"
            style={{ textAlign: "center" }}
          >
            Existing Blogs
          </h2>
          <div className="mt-4">{renderBlogs()}</div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ManageBlog;
