import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ContentsState } from "../Context/ContentProvider";
import Swal from "sweetalert2";
import ToggleSwitch from "./common/ToggleButton";
import Shimmer from "./common/Shimmer"; // Import the Shimmer component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faLightbulb,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

const ManageServices = ({ selectedUser }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState(null);
  const [services, setServices] = useState([]);
  const [shortDesc, setShortDesc] = useState("");
  const [editingServiceId, setEditingServiceId] = useState(null); // To track editing
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const { isDarkMode, toggleDarkMode } = ContentsState();

  const { user } = ContentsState();

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      postDetails(file);
    }
  };

  const postDetails = async (file) => {
    // Validate file type
    if (!file || (file.type !== "image/jpeg" && file.type !== "image/png")) {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }
  
    // Validate file size (2MB limit)
    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }
  
    try {
      // Prepare form data
      const formData = new FormData();
      formData.append("file", file);
  
      // Validate `currentUser` and set folder name
      const folderName = currentUser?.url || "default"; // Fallback to "default" folder if `currentUser.url` is unavailable
      formData.append("folder", folderName);
  
      // Send the request to the upload endpoint
      const response = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Check the response and update state
      if (response.data?.fileName) {
        const uploadedImageName = response.data.fileName;
        setImage(uploadedImageName); // Set only the image name
        toast.success("Image uploaded successfully");
      } else {
        throw new Error("Invalid response from server. File name not found.");
      }
    } catch (error) {
      // Handle errors with detailed logs
      if (error.response) {
        console.error("Error response from server:", error.response.data);
        toast.error(error.response.data.message || "Upload failed");
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        toast.error("No response from the server. Please try again later.");
      } else {
        console.error("Error in request setup:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}/api/services`, {
        params: { url: currentUser.url },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      const { data } = response;
      if (data) {
        setServices(data.services || []);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser]);
  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const handleSubmit = async () => {
    try {
      if (!title || !content || !image) {
        throw new Error("Fill all the fields");
      }
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };

      if (editingServiceId) {
        // Update existing service
        const response = await axios.put(
          `${baseUrl}/api/services/updateService/${editingServiceId}`,
          {
            emailId: currentUser.email,
            serviceDesc: content,
            serviceTitle: title,
            imageId: image,
            shortDesc: shortDesc,
          },
          config
        );

        if (!response.data) {
          throw new Error("Failed to update service");
        }

        toast.success("Update Success");
        setEditingServiceId(null);
      } else {
        // Create new service
        const response = await axios.post(
          `${baseUrl}/api/services/postService`,
          {
            emailId: currentUser.email,
            serviceDesc: content,
            serviceTitle: title,
            imageId: image,
            shortDesc: shortDesc,
          },
          config
        );

        if (!response.data) {
          throw new Error("Failed to upload service");
        }

        toast.success("Upload Success");
      }

      // Clear form fields after submission
      setTitle("");
      setContent("");
      setImage(null);
      setShortDesc("");

      fetchData(); // Refresh the services list after upload
    } catch (error) {
      console.error("Error uploading service:", error);
      toast.error(error.message);
    }
  };
  const handleToggleStatus = (index, status) => {
    const statusReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/services/changeStatus`,
          {
            params: {
              emailId: currentUser.email,
              index: index,
              status: status,
            },
          },
          config
        );
        fetchData();
        toast.success("Status Changed");
      } catch (error) {
        console.error("Error changing status", error);
        toast.error("Error Changing Status");
      }
    };

    statusReq();
  };

  const handleEdit = (service) => {
    setTitle(service.serviceTitle);
    setContent(service.serviceDesc);
    // setImage(service.imageId);
    setShortDesc(service.shortDesc);
    setEditingServiceId(service._id);
  };

  const handleDelete = async (serviceId) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            Authorization: "Bearer " + user.token,
          },
        };

        const response = await axios.put(
          `${baseUrl}/api/services/deleteService/`,
          {  currentUser: currentUser.url, serviceId: serviceId, emailId: currentUser.email },
          config
        );

        if (response.status !== 200) {
          throw new Error("Failed to delete service");
        }
        Swal.fire({
          title: "Deleted!",
          text: "Your Service has been deleted.",
          icon: "success",
        });
        fetchData(); // Refresh the services list after deletion
      } catch (error) {
        console.error("Error deleting service:", error);
        toast.error(error.message);
      }
    };

    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this Service",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  const renderServices = () => {
    if (isLoading) {
      return <Shimmer />; // Show shimmer effect while loading
    }

    if (services.length === 0) {
      return (
        <div className="bg-white p-4 rounded shadow-md justify-center font-bold  text-gray-700">
          No services to display.
        </div>
      );
    }

    const serviceRows = [];
    for (let i = 0; i < services.length; i += 3) {
      const row = services.slice(i, i + 3);
      const rowContent = (
        <div key={i} className="flex flex-wrap -mx-4">
          {row.map((service, index) => (
            <div key={i + index} className="w-full md:w-1/3 px-4 mb-4">
              <div className="bg-card p-4 rounded shadow">
                <h2 className="text-xl font-bold mb-2  text-gray-700">
                  {service.serviceTitle}
                </h2>
                <img
                  src= {`${baseUrl}/uploads/${currentUser.url}/${service.imageId}`}
                  alt={service.serviceTitle}
                  className="mb-2 w-full h-32 object-cover rounded"
                />
                <div
                  dangerouslySetInnerHTML={{ __html: service.serviceDesc }}
                  className="mb-2  text-gray-700"
                ></div>
                <div className="flex justify-between mt-4">
                  <button
                    onClick={() => handleEdit(service)}
                    className="bg-yellow-500 text-white px-2 py-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z" />
                    </svg>
                  </button>
                  <ToggleSwitch
                    isActive={service.status === "active" ? true : false}
                    onToggle={() =>
                      handleToggleStatus(
                        i + index,
                        service.status === "active" ? "inActive" : "active"
                      )
                    }
                  />
                  <button
                    onClick={() => handleDelete(i + index)}
                    className="bg-red-500 text-white px-2 py-2 rounded"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
      serviceRows.push(rowContent);
    }

    return serviceRows;
  };

  return (
    <div
      className={`flex h-screen bg-gray-200 overflow-hidden ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      {!selectedUser ? (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : (
        <span></span>
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser ? (
          <Navbar
            toggleSidebar={toggleSidebar}
            isOpen={isOpen}
            toggleDarkMode={toggleDarkMode}
          />
        ) : (
          <span></span>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage Services
          </h1>
          {isFormVisible && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Service Title:
                  </label>
                  <input
                    type="text"
                    placeholder="Service Title"
                    value={title}
                    onChange={handleTitleChange}
                    className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="imageInput"
                    className=" text-gray-700 text-sm font-bold mb-2 cursor-pointer flex mt-10"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="size-5"
                    >
                      <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
                      <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                    </svg>
                    <span className="ml-1">Select Service Image</span>
                  </label>
                  <input
                    id="imageInput"
                    type="file"
                    accept="image/*"
                    onChange={(e) => postDetails(e.target.files[0])}
                    className="hidden"
                  />
                  {image && (
                    <div className="relative w-32 h-32 mb-4">
                      <img
                        src= {`${baseUrl}/uploads/${currentUser.url}/${image}`}
                        alt="Selected"
                        className="w-full h-full object-cover rounded-lg"
                      />
                      <div className="absolute inset-0 flex items-center justify-center rounded-lg"></div>
                    </div>
                  )}
                  <p className="text-gray-700 font-bold">
                    <FontAwesomeIcon
                      icon={faLightbulb}
                      className="text-yellow-300"
                    />{" "}
                    [Recommended size 1080 × 1080 and File size less than 1 MB]
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Service Short Description:
                </label>
                <input
                  type="text"
                  placeholder="Service Short Description"
                  value={shortDesc}
                  className="p-2 border border-gray-300 rounded w-full  bg-slate-50 text-gray-700"
                  onChange={(e) => setShortDesc(e.target.value)}
                />
              </div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Service Description:
              </label>
              <ReactQuill
                value={content}
                onChange={handleContentChange}
                className="mb-6 my-quill-editor border bg-slate-50"
                style={{ height: "200px" }}
              />
              <div className="flex justify-center md:justify-start space-x-4">
                <button
                  onClick={handleSubmit}
                  className="bg-blue-500 text-white p-2 mt-9 rounded"
                >
                  {editingServiceId ? "Update Service" : "Upload Service"}
                </button>
              </div>
            </div>
          )}
          <div className="flex justify-center mt-2">
            <button
              onClick={toggleFormVisibility}
              className="text-gray-700 focus:outline-none"
            >
              <svg
                className={`w-6 h-6 transform transition-transform duration-300 ${
                  isFormVisible ? "rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
          </div>

          <div className="mt-8">
            <h2
              className="text-2xl font-bold mb-5 uppercase  text-gray-700"
              style={{ textAlign: "center" }}
            >
              Existing Services
            </h2>
            {renderServices()}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageServices;
