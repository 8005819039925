import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill styles
import ReactQuill from "react-quill"; // Import ReactQuill
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  faExclamation,
  faLightbulb,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleSwitch from "./common/ToggleButton";
import Swal from "sweetalert2";

const ManageAbout = ({ selectedUser }) => {
  const [content, setContent] = useState(""); // State for ReactQuill content
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const { user } = ContentsState();
  const [aboutImage, setAboutImage] = useState(null); // Added state for about image
  const [mapURL, setMapURL] = useState("");
  const [image, setImage] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [logoImgTog, setLogoImgTog] = useState(false);
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    // const baseUrl = "http://localhost:5000";
  const [logoText, setLogoText] = useState("");
  const [aboutData, setAboutData] = useState(null);
  const { isDarkMode, toggleDarkMode } = ContentsState();
  const Swal = require("sweetalert2");

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (selectedUser) {
      setCurrentUser(selectedUser);
    } else {
      setCurrentUser(user);
    }
  }, [selectedUser, user]);

  useEffect(() => {
    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  const postDetails = async (logoImages) => {
    try {
      // Validate if an image is selected
      if (!logoImages) {
        toast.error("No image selected");
        return;
      }
  
      // Validate image size (1MB limit)
      if (logoImages.size > 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1MB");
        return;
      }
  
      // Validate image type
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(logoImages.type)) {
        toast.error("Only JPEG and PNG images are allowed");
        return;
      }
  
      // Create FormData for upload
      const formData = new FormData();
      formData.append("file", logoImages);
  
      // Dynamically set folder name
      const folderName = currentUser?.url || "default"; // Fallback to "default" if `currentUser.url` is not available
      formData.append("folder", folderName);
  
      // Send the request to upload the file
      const response = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handle successful response
      const uploadedImageName = response.data.fileName; // Assuming `fileName` is returned by the backend
      setLogoImage(uploadedImageName); // Update the state with the uploaded image name
      toast.success("Image uploaded successfully");
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Error response from server:", error.response.data);
        toast.error(error.response.data.message || "Upload failed");
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        toast.error("No response from the server. Please try again later.");
      } else {
        console.error("Error in request setup:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  

  const postAboutImage = async (aboutImg) => {
    try {
      // Validate if an image is selected
      if (!aboutImg) {
        toast.error("No image selected");
        return;
      }
  
      // Validate image size (1MB limit)
      if (aboutImg.size > 1 * 1024 * 1024) {
        toast.error("Image size should be less than 1MB");
        return;
      }
  
      // Validate image type
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(aboutImg.type)) {
        toast.error("Only JPEG and PNG images are allowed");
        return;
      }
  
      // Create FormData for upload
      const formData = new FormData();
      formData.append("file", aboutImg); // Append the selected file
  
      // Dynamically set folder name
      const folderName = currentUser?.url || "default"; // Use "default" if `currentUser.url` is not available
      formData.append("folder", folderName);
  
      // Send the request to upload the file
      const response = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handle successful response
      const uploadedImageName = response.data.fileName; // Assuming `fileName` is returned by the backend
      setAboutImage(uploadedImageName); // Update the state with the uploaded image name
      toast.success("Image uploaded successfully");
    } catch (error) {
      // Handle errors
      if (error.response) {
        console.error("Error response from server:", error.response.data);
        toast.error(error.response.data.message || "Upload failed");
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        toast.error("No response from the server. Please try again later.");
      } else {
        console.error("Error in request setup:", error.message);
        toast.error("Something went wrong. Please try again.");
      }
    }
  };
  
  

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/about`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setContent(data.aboutContent); // Set content to the data's aboutContent
        setContactNumber(data.contactNumber);
        setAddress(data.address);
        setEmail(data.email);
        setLogoImage(data.logoid); // Set the image URL
        setImage(data.logoid); // Display the fetched image
        setMapURL(data.mapURL); // Set the map URL
        setAboutImage(data.aboutImage);
        setLogoText(data.logoText);
        if (data.logoText) {
          setLogoImgTog(false);
        } else {
          setLogoImgTog(true);
        }
        setAboutData(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleSubmit = async () => {
    // Validate required fields
    if (
      !content ||
      !contactNumber ||
      !address ||
      !email ||
      !mapURL ||
      (!logoText && !logoImage)
    ) {
      toast.error("Fill all the fields");
      return;
    }


    //   console.log("Submitting data:", {
    //     currentContent: content,
    //     address: address,
    //     contactNumber: contactNumber,
    //     email: email,
    //     logoImage: logoImage, // Ensure logoImage is defined and assigned correctly
    //     user: currentUser, // Ensure user object contains necessary data
    //     mapURL: mapURL,
    //     aboutImage: aboutImage,
    //     logoText: logoText,
    // });

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      };

      const postData = {
        currentContent: content,
        address: address,
        contactNumber: contactNumber,
        email: email,
        logoImage: logoImage, // Ensure logoImage is defined and assigned correctly
        user: currentUser, // Ensure user object contains necessary data
        mapURL: mapURL,
        aboutImage: aboutImage,
        logoText: logoText,
      };

      const response = await axios.post(
        `${baseUrl}/api/about/postAbout`,
        postData,
        config
      );
      fetchData();
      toast.success("Upload Success");
    } catch (err) {
      console.error(err);
      toast.error("Error submitting data");
    }
  };


  const handleDelete = async (imgUrl) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/slides/deleteSlide`,
          {
            params: {
              currentUser: currentUser.url,
              emailId: currentUser.email,
              imageUrl: imgUrl,
            },
          },
          config
        );
        if (response.status !== 200) {
          throw new Error("Failed to delete slide");
        }
        Swal.fire({
          title: "Deleted!",
          text: "Your Slide has been deleted.",
          icon: "success",
        });
         // Uncomment below if you want to refresh the page after deleting
      window.location.reload(); // Reloads the page

      // Or, if you're using a fetchData function to get the updated data:
      // fetchData();
      } catch (error) {
        console.error("Error deleting slide:", error);
        toast.error("Error deleting slide");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this slide?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  
  return (
    <div className={`flex h-screen bg-gray-400 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser ? (
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      ) : (
        <span></span>
      )}
      <div
        className={`flex-1 flex flex-col ${
          isOpen ? "ml-0" : ""
        } overflow-y-auto`}
      >
        {!selectedUser ? (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen}  toggleDarkMode={toggleDarkMode} />
        ) : (
          <span></span>
        )}
        <div className="p-4">
          <h1
            className="text-2xl font-bold mb-4 uppercase text-gray-700"
            style={{ textAlign: "" }}
          >
            Manage About
          </h1>
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            className="mb-6 my-quill-editor bg-slate-50" // Added margin bottom for spacing and custom class
            style={{ height: "200px" }}
          />

          <div className="flex flex-col md:flex-row mb-6 mt-16">
            <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Contact Number:
                <input
                  type="text"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50"
                  placeholder="Enter contact number"
                />
              </label>
            </div>

            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Email:
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50"
                  placeholder="Enter email"
                />
              </label>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mb-6">
            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Address:
              </label>
              <div className="relative">
                <textarea
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline md:w-2/3 h-24 resize-none bg-slate-50 text-gray-700"
                  placeholder="Enter address"
                />
              </div>
            </div>

            <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Map URL:
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={mapURL}
                  onChange={(e) => setMapURL(e.target.value)}
                  className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3 bg-slate-50 text-gray-700"
                  placeholder="Enter Map URL"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row mb-6">
          <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
          <div className="flex items-center mb-4">
            <div className="flex items-center mr-4">
              <input
                type="radio"
                id="logoImage"
                name="logoOption"
                value="image"
                checked={logoImgTog}
                onChange={() => {
                  setLogoImgTog(true);
                  setLogoText(null);
                  if (aboutData.logoid) {
                    setLogoImage(aboutData.logoid);
                  }
                }}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label htmlFor="logoImage" className="ml-2 block text-gray-700 text-sm font-bold">
                Logo Image
              </label>
            </div>
            <div className="flex items-center ">
              <input
                type="radio"
                id="logoText"
                name="logoOption"
                value="text"
                checked={!logoImgTog}
                onChange={() => {
                  setLogoImgTog(false);
                  setLogoImage(null);
                  if (aboutData.logoText) {
                    setLogoText(aboutData.logoText);
                  }
                }}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 "
              />
              <label htmlFor="logoText" className="ml-2 block text-gray-700 text-sm font-bold">
                Logo Text
              </label>
            </div>
          </div>
          {logoImgTog ? (
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Logo Image:
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                onChange={(e) => postDetails(e.target.files[0])}
                className="appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline"
              />
              <p className="text-gray-400 mt-1 text-sm">
                <FontAwesomeIcon
                  icon={faLightbulb}
                  className="text-yellow-300 mr-1"
                />
                [Recommended size 500 × 500 and File size less than 1 MB]
              </p>
            </label>
          ) : (
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Logo Text:
              <input
                type="text"
                value={logoText}
                onChange={(e) => setLogoText(e.target.value)}
                className="shadow appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline w-full md:w-2/3  bg-slate-50 text-gray-700"
                placeholder="Enter Logo Text"
              />
            </label>
          )}
          {logoImgTog && logoImage && (
            <div className="relative w-32 h-32 mt-2">
              <img
                src={`${baseUrl}/uploads/${currentUser.url}/${logoImage}`}
                alt=""
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center rounded-lg"></div>
              <button
                  onClick={() => handleDelete(logoImage)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
            </div>
            
          )}
         
        </div>



            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                About Image:
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => postAboutImage(e.target.files[0])}
                  className=" appearance-none border rounded py-2 px-3 mt-1 focus:outline-none focus:shadow-outline"
                />
                <p className="text-black-400">
                  <FontAwesomeIcon
                    icon={faLightbulb}
                    className="text-yellow-300"
                  />{" "}
                  [Recommended size 500 × 500 and File size less than 1 MB]
                </p>
              </label>
              {aboutImage && (
                <div className="relative w-32 h-32 mt-2">
                  <img
                    src={`${baseUrl}/uploads/${currentUser.url}/${aboutImage}`}
                    alt=""
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              )}
              <button
                  onClick={() => handleDelete(aboutImage)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
            </div>
          </div>

          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageAbout;
