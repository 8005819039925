import React, { useState, useEffect } from "react";
import Sidebar from "./common/Sidebar";
import Navbar from "./common/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContentsState } from "../Context/ContentProvider";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

const ManageSEO = ({ selectedUser }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [image, setImage] = useState("");
  const [keywords, setKeywords] = useState("");
  const { user, isDarkMode, toggleDarkMode } = ContentsState();
  const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;
  const Swal = require("sweetalert2");

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const postDetails = async (file) => {
    if (!file) {
      toast.error("No image selected");
      return;
    }

    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      toast.error("Invalid file type. Only JPEG and PNG are allowed.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should be less than 2MB");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    // Assuming you have a folder name, for example from a variable `folderName`
    const folderName = currentUser.url || "def";  // Replace this with your dynamic folder name logic
    formData.append("folder", folderName); // Append the folder name to the form data
    
    try {
      const response = await axios.post(`${baseUrl}/api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      const uploadedImageName = response.data.fileName; // Assuming `fileName` is returned by the backend
      // console.log("Uploaded Image Name:", uploadedImageName); // Debugging
      setImage(uploadedImageName); // Set only the image name
      toast.success("Image uploaded successfully");
    } catch (error) {
      // Log the error for debugging
      if (error.response) {
        console.error('Error response from server:', error.response.data);
      } else if (error.request) {
        console.error('No response received from server:', error.request);
      } else {
        console.error('Error in request setup:', error.message);
      }
    }
  };

  const fetchData = async () => {
    if (!currentUser) return;

    try {
      const response = await axios.get(`${baseUrl}/api/seo`, {
        params: { url: currentUser.url },
      });
      const { data } = response;
      if (data) {
        setTitle(data.seoTitle);
        setDescription(data.seoDescription);
        setKeywords(data.keywords);
        setImage(data.metaImage);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching SEO data");
    }
  };

  useEffect(() => {
    setCurrentUser(selectedUser || user);
  }, [selectedUser, user]);

  useEffect(() => {
    fetchData();
  }, [currentUser]);

  const handleSubmit = async () => {
    if (!title || !description || !image || !keywords) {
      toast.error("Please fill all the fields");
      return;
    }

    // console.log("Submitting data:", {
    //   emailId: currentUser.email,
    //   seoTitle: title,
    //   seoDescription: description,
    //   keywords,
    //   metaImage: image,
    //   user: currentUser,
    // });

    try {
      const response = await axios.post(
        `${baseUrl}/api/seo/postSeo`,
        {
          emailId: currentUser.email,
          seoTitle: title,
          seoDescription: description,
          keywords: keywords,
          metaImage: image, // Include the image state here
          user: currentUser,
        },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      toast.success("SEO Metadata uploaded successfully");
      fetchData();
    } catch (error) {
      console.error("Error submitting SEO metadata:", error);
      toast.error("Error uploading metadata");
    }
  };

  const handleDelete = async (imgUrl) => {
    const deleteReq = async () => {
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        };
        const response = await axios.put(
          `${baseUrl}/api/slides/deleteSlide`,
          {
            params: {
              currentUser: currentUser.url,
              emailId: currentUser.email,
              imageUrl: imgUrl,
            },
          },
          config
        );
        if (response.status !== 200) {
          throw new Error("Failed to delete slide");
        }
        Swal.fire({
          title: "Deleted!",
          text: "Your Slide has been deleted.",
          icon: "success",
        });
         // Uncomment below if you want to refresh the page after deleting
      window.location.reload(); // Reloads the page

      // Or, if you're using a fetchData function to get the updated data:
      // fetchData();
      } catch (error) {
        console.error("Error deleting slide:", error);
        toast.error("Error deleting slide");
      }
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this slide?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteReq();
      }
    });
  };

  return (
    <div className={`flex h-screen bg-gray-200 w-full ${isDarkMode ? "dark-mode" : ""}`}>
      {!selectedUser && <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />}
      <div className={`flex-1 flex flex-col ${isOpen ? "ml-0" : ""} overflow-y-auto`}>
        {!selectedUser && (
          <Navbar toggleSidebar={toggleSidebar} isOpen={isOpen} toggleDarkMode={toggleDarkMode} />
        )}
        <div className="p-4">
          <h1 className="text-2xl font-bold mb-4 uppercase text-gray-700">Manage SEO</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">SEO Title</label>
              <input
                type="text"
                placeholder="SEO Title"
                value={title}
                onChange={handleTitleChange}
                className="p-2 border border-gray-300 rounded w-full bg-slate-50 text-gray-700"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="imageInput" className="text-gray-700 text-sm font-bold mb-2 cursor-pointer flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                  <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
                  <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
                </svg>
                <span className="ml-1">Select Image</span>
              </label>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                onChange={(e) => postDetails(e.target.files[0])}
                className="hidden"
              />
              {image && (
                <div className="relative w-32 h-32 mb-4">
                  <img src={`${baseUrl}/uploads/${currentUser.url}/${image}`} alt="Uploaded" className="w-full h-full object-cover rounded-lg" />
                </div>
              )}
               <button
                  onClick={() => handleDelete(image)}
                  className="bg-red-500 text-white px-2 py-1 rounded"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="size-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              <p className="text-gray-700 font-bold">
                <FontAwesomeIcon icon={faLightbulb} className="text-yellow-300" /> [Recommended size 1080 × 1080 and File size less than 2MB]
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mb-6">
            <div className="w-full md:w-1/2 md:mr-4 mb-4 md:mb-0">
              <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
              <textarea
                placeholder="SEO Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="shadow appearance-none border rounded py-2 px-3 focus:outline-none focus:shadow-outline w-full h-24 resize-none bg-slate-50 text-gray-700"
              />
            </div>
            <div className="w-full md:w-1/2">
              <label className="block text-gray-700 text-sm font-bold mb-2">Keywords</label>
              <textarea
                placeholder="SEO Keywords"
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                className="shadow appearance-none border rounded py-2 px-3 focus:outline-none focus:shadow-outline w-full h-24 resize-none bg-slate-50 text-gray-700"
              />
            </div>
          </div>
          <div className="flex justify-center md:justify-start space-x-4">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-200"
            >
              Upload Metadata
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ManageSEO;
